import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Chip,
  Stack,
  CardMedia,
  Divider,
  Alert,
  CircularProgress,
} from '@mui/material';
import { fetchVideoDetail, transformVideoData } from '../services/api';

const SeriesDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [series, setSeries] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadSeriesDetail = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await fetchVideoDetail(id);
        if (response.code === 1 && response.list?.length > 0) {
          const seriesData = transformVideoData(response.list[0]);
          setSeries(seriesData);
        } else {
          setError('未找到该剧集信息');
        }
      } catch (err) {
        console.error('Failed to load series detail:', err);
        setError('加载失败，请稍后重试');
      } finally {
        setLoading(false);
      }
    };

    loadSeriesDetail();
  }, [id]);

  const handleEpisodeClick = (episode) => {
    navigate(`/series/${id}/play/${episode}`);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  if (!series) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Alert severity="info">暂无数据</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Paper elevation={3}>
            <CardMedia
              component="img"
              image={series.thumbnail}
              alt={series.title}
              sx={{ width: '100%', height: 'auto' }}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              {series.title}
            </Typography>
            <Stack direction="row" spacing={1} sx={{ mb: 2, flexWrap: 'wrap', gap: 1 }}>
              {series.tags.map((tag, index) => (
                <Chip key={index} label={tag} variant="outlined" />
              ))}
            </Stack>
            <Typography variant="body1" paragraph>
              {series.description}
            </Typography>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={6} sm={3}>
                <Typography variant="body2" color="text.secondary">
                  地区：{series.region}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography variant="body2" color="text.secondary">
                  年份：{series.year}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography variant="body2" color="text.secondary">
                  评分：{series.rating}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography variant="body2" color="text.secondary">
                  播放量：{series.views}
                </Typography>
              </Grid>
              {series.doubanScore && (
                <Grid item xs={12}>
                  <Typography variant="body2" color="text.secondary">
                    豆瓣评分：{series.doubanScore} ({series.scoreCount}人评分)
                  </Typography>
                </Grid>
              )}
              {series.director && (
                <Grid item xs={12}>
                  <Typography variant="body2" color="text.secondary">
                    导演：{series.director}
                  </Typography>
                </Grid>
              )}
              {series.cast?.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="body2" color="text.secondary">
                    演员：{series.cast.join(' / ')}
                  </Typography>
                </Grid>
              )}
              {series.language && (
                <Grid item xs={12}>
                  <Typography variant="body2" color="text.secondary">
                    语言：{series.language}
                  </Typography>
                </Grid>
              )}
              {series.duration && (
                <Grid item xs={12}>
                  <Typography variant="body2" color="text.secondary">
                    单集片长：{series.duration}分钟
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={3}>
            <Typography variant="h6" sx={{ p: 2, pb: 1 }}>
              剧集列表 ({series.status})
            </Typography>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Grid container spacing={1}>
                {series.episodes.map((ep) => (
                  <Grid item xs={6} sm={4} md={3} lg={2} key={ep.episode}>
                    <ListItemButton
                      onClick={() => handleEpisodeClick(ep.episode)}
                      sx={{
                        border: '1px solid',
                        borderColor: 'divider',
                        borderRadius: 1,
                        textAlign: 'center',
                        '&:hover': {
                          borderColor: 'primary.main',
                        },
                      }}
                    >
                      <ListItemText
                        primary={ep.title}
                        primaryTypographyProps={{
                          variant: 'body2',
                        }}
                      />
                    </ListItemButton>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SeriesDetail; 