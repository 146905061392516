import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Hls from 'hls.js';
import {
  Container,
  Grid,
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  Tooltip,
  Divider,
  Stack,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ShareIcon from '@mui/icons-material/Share';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SeriesCard from '../components/SeriesCard';
import { 
  fetchVideoDetail, 
  transformVideoData, 
  fetchRelatedVideos, 
  addWatchedVideo, 
  saveContinueVideo, 
  getVideoProgress, 
  saveVideoProgress, 
  addToHistory,
  addToFavorites,
  removeFromFavorites,
  isFavorite
} from '../services/api';

const SeriesPlayer = () => {
  const { id, episode } = useParams();
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const hlsRef = useRef(null);
  const [series, setSeries] = useState(null);
  const [currentEpisode, setCurrentEpisode] = useState(parseInt(episode) || 1);
  const [isLiked, setIsLiked] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [relatedSeries, setRelatedSeries] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadSeriesDetail = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await fetchVideoDetail(id);
        if (response.code === 1 && response.list?.length > 0) {
          const seriesData = transformVideoData(response.list[0]);
          setSeries(seriesData);
          setIsSaved(seriesData.isFavorite);
          
          // 加载相关推荐
          try {
            const relatedResponse = await fetchRelatedVideos(response.list[0]);
            if (relatedResponse.code === 1) {
              // 过滤掉当前视频，并限制数量
              const relatedVideos = relatedResponse.list
                .filter(item => item.vod_id.toString() !== id)
                .slice(0, 6)
                .map(transformVideoData);
              setRelatedSeries(relatedVideos);
            }
          } catch (err) {
            console.error('Failed to load related videos:', err);
          }

          // 初始化当前集数
          const initialEpisode = parseInt(episode) || 1;
          setCurrentEpisode(initialEpisode);

          // 确保视频数据已加载
          const video = videoRef.current;
          if (video && seriesData.episodes) {
            const currentEpisodeData = seriesData.episodes.find(ep => ep.episode === initialEpisode);
            if (currentEpisodeData) {
              initializeVideoPlayer(video, currentEpisodeData.videoUrl, seriesData.id, initialEpisode);
            }
          }
        } else {
          setError('未找到该剧集信息');
        }
      } catch (err) {
        console.error('Failed to load series detail:', err);
        setError('加载失败，请稍后重试');
      } finally {
        setLoading(false);
      }
    };

    loadSeriesDetail();
  }, [id, episode]);

  // 提取视频初始化逻辑为独立函数
  const initializeVideoPlayer = (video, videoUrl, seriesId, episodeNumber) => {
    if (!video || !videoUrl) return;

    // 保存观看记录
    addWatchedVideo(seriesId);
    saveContinueVideo(seriesId, episodeNumber);
    
    // 更新观看历史
    if (series) {
      const updatedSeries = {
        ...series,
        continueEpisode: episodeNumber
      };
      addToHistory(updatedSeries);
    }

    // 恢复播放进度
    const savedProgress = getVideoProgress(seriesId, episodeNumber);

    // iOS 设备特殊处理
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) || 
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    if (Hls.isSupported()) {
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }

      const hls = new Hls({
        enableWorker: true,
        debug: false,
        maxBufferLength: 30,
        maxMaxBufferLength: 600,
        maxBufferSize: 60 * 1000 * 1000,
        maxBufferHole: 0.5,
        lowLatencyMode: true
      });
      
      hlsRef.current = hls;
      hls.loadSource(videoUrl);
      hls.attachMedia(video);
      
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        if (savedProgress > 0) {
          video.currentTime = savedProgress;
        }
        
        if (isIOS) {
          video.muted = true;
          video.play().then(() => {
            setTimeout(() => {
              video.muted = false;
            }, 100);
          }).catch(() => {
            video.muted = false;
            console.log('等待用户手动播放');
          });
        }
      });

      hls.on(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              console.log("网络错误，尝试恢复...");
              hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              console.log("媒体错误，尝试恢复...");
              hls.recoverMediaError();
              break;
            default:
              console.log("无法恢复的错误");
              hls.destroy();
              setError('视频加载失败，请刷新页面重试');
              break;
          }
        }
      });
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = videoUrl;
      video.addEventListener('loadedmetadata', () => {
        if (savedProgress > 0) {
          video.currentTime = savedProgress;
        }
        
        if (isIOS) {
          video.muted = true;
          video.play().then(() => {
            setTimeout(() => {
              video.muted = false;
            }, 100);
          }).catch(() => {
            video.muted = false;
            console.log('等待用户手动播放');
          });
        }
      });
    }
  };

  useEffect(() => {
    if (!series || !videoRef.current) return;

    const video = videoRef.current;
    const currentEpisodeData = series.episodes.find(ep => ep.episode === currentEpisode);
    if (!currentEpisodeData) {
      setError('未找到该集数据');
      return;
    }

    // 监听播放进度变化
    const handleTimeUpdate = () => {
      const progress = video.currentTime;
      if (progress > 0) {
        saveVideoProgress(series.id, currentEpisode, progress);
      }
    };

    // 监听播放结束
    const handleEnded = () => {
      saveVideoProgress(series.id, currentEpisode, 0);
      const nextEpisode = series.episodes.find(ep => ep.episode === currentEpisode + 1);
      if (nextEpisode) {
        handleEpisodeClick(nextEpisode.episode);
      }
    };

    // 监听播放错误
    const handleError = (error) => {
      console.error('视频播放错误:', error);
      setError('视频加载失败，请刷新页面重试');
    };

    // 监听播放点击事件
    const handlePlay = () => {
      if (video.paused) {
        video.play().catch(error => {
          console.error('播放失败:', error);
          video.load();
          video.play().catch(error => {
            console.error('重试播放失败:', error);
            setError('播放失败，请重试');
          });
        });
      }
    };

    video.addEventListener('timeupdate', handleTimeUpdate);
    video.addEventListener('ended', handleEnded);
    video.addEventListener('error', handleError);
    video.addEventListener('play', handlePlay);

    // 初始化视频播放器
    initializeVideoPlayer(video, currentEpisodeData.videoUrl, series.id, currentEpisode);

    // 定期保存播放进度
    const progressInterval = setInterval(() => {
      if (video.currentTime > 0) {
        saveVideoProgress(series.id, currentEpisode, video.currentTime);
      }
    }, 5000);

    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }
      clearInterval(progressInterval);
      video.removeEventListener('timeupdate', handleTimeUpdate);
      video.removeEventListener('ended', handleEnded);
      video.removeEventListener('error', handleError);
      video.removeEventListener('play', handlePlay);
    };
  }, [series, currentEpisode]);

  const handleEpisodeClick = (ep) => {
    setCurrentEpisode(ep);
    navigate(`/series/${id}/play/${ep}`);
  };

  const handleLike = () => {
    setIsLiked(!isLiked);
    setSnackbar({
      open: true,
      message: isLiked ? '已取消点赞' : '点赞成功',
      severity: 'success'
    });
  };

  const handleSave = () => {
    if (!series) return;

    if (isSaved) {
      removeFromFavorites(series.id);
    } else {
      addToFavorites(series);
    }
    setIsSaved(!isSaved);
    setSnackbar({
      open: true,
      message: isSaved ? '已取消收藏' : '收藏成功',
      severity: 'success'
    });
  };

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href);
    setSnackbar({
      open: true,
      message: '链接已复制到剪贴板',
      severity: 'success'
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  if (!series) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Alert severity="info">暂无数据</Alert>
      </Container>
    );
  }

  const currentEpisodeData = series.episodes.find(ep => ep.episode === currentEpisode);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={9}>
          <Paper elevation={3}>
            <Box sx={{ position: 'relative', paddingTop: '56.25%' }}>
              <video
                ref={videoRef}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%'
                }}
                controls
                playsInline
              />
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography variant="h5" gutterBottom>
                {series.title} - {currentEpisodeData?.title}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  {series.views} 次观看 • {series.rating} 分 • {series.status}
                </Typography>
                <Stack direction="row" spacing={1}>
                  <Tooltip title={isLiked ? '取消点赞' : '点赞'}>
                    <IconButton onClick={handleLike}>
                      {isLiked ? <ThumbUpIcon color="primary" /> : <ThumbUpOutlinedIcon />}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={isSaved ? '取消收藏' : '收藏'}>
                    <IconButton onClick={handleSave}>
                      {isSaved ? <BookmarkIcon color="primary" /> : <BookmarkBorderIcon />}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="分享">
                    <IconButton onClick={handleShare}>
                      <ShareIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Box>
              <Divider />
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" gutterBottom>
                  剧集简介
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {series.description}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={3}>
          <Paper elevation={3}>
            <Typography variant="h6" sx={{ p: 2, pb: 1 }}>
              选集
            </Typography>
            <List
              sx={{
                maxHeight: 400,
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                  width: 8,
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: 'rgba(0,0,0,0.1)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  borderRadius: 4,
                },
              }}
            >
              {series.episodes.map((ep) => (
                <ListItem
                  key={ep.episode}
                  disablePadding
                  sx={{
                    bgcolor: ep.episode === currentEpisode ? 'action.selected' : 'inherit',
                  }}
                >
                  <ListItemButton onClick={() => handleEpisodeClick(ep.episode)}>
                    <ListItemText
                      primary={ep.title}
                      primaryTypographyProps={{
                        variant: 'body2',
                        color: ep.episode === currentEpisode ? 'primary' : 'inherit',
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Paper>

          <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
            相关推荐
          </Typography>
          <Stack spacing={2}>
            {relatedSeries.map((series) => (
              <SeriesCard key={series.id} series={series} compact />
            ))}
          </Stack>
        </Grid>
      </Grid>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default SeriesPlayer; 