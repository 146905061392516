import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Typography, Box, Chip, Button, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import HistoryIcon from '@mui/icons-material/History';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { styled } from '@mui/material/styles';
import { addToFavorites, removeFromFavorites } from '../services/api';

const StyledCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  transition: 'transform 0.2s ease-in-out',
  cursor: 'pointer',
  '&:hover': {
    transform: 'translateY(-4px)',
    '& .MuiCardMedia-root': {
      filter: 'brightness(0.8)',
    },
    '& .play-icon': {
      opacity: 1,
    },
  },
}));

const PlayIconWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  opacity: 0,
  transition: 'opacity 0.2s ease-in-out',
  zIndex: 1,
  width: 64,
  height: 64,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  borderRadius: '50%',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  }
}));

const InfoOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  background: 'linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.6) 60%, rgba(0,0,0,0.4) 100%)',
  padding: theme.spacing(2),
  color: 'white',
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontWeight: 500,
  textShadow: '1px 1px 2px rgba(0,0,0,0.8)',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  lineHeight: 1.2,
  marginBottom: theme.spacing(1),
}));

const StatusChip = styled(Chip)(({ theme }) => ({
  position: 'absolute',
  top: 8,
  right: 8,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  color: 'white',
  height: 24,
  '& .MuiChip-label': {
    px: 1,
    fontSize: '0.75rem',
  },
  '& .MuiChip-icon': {
    color: 'white',
    fontSize: '1rem',
  }
}));

const ContinueButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  minHeight: 0,
  padding: '4px 8px',
  fontSize: '0.75rem',
  marginTop: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  '& .MuiButton-startIcon': {
    marginRight: 4,
    '& > *:first-of-type': {
      fontSize: '1rem',
    },
  },
}));

const FavoriteButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 8,
  left: 8,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  color: 'white',
  padding: 4,
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  '& svg': {
    fontSize: '1.2rem',
  },
}));

const SeriesCard = ({ series, compact = false }) => {
  const navigate = useNavigate();
  const [isFavorite, setIsFavorite] = useState(series.isFavorite);

  const handleClick = () => {
    if (series.continueEpisode) {
      navigate(`/series/${series.id}/play/${series.continueEpisode}`);
    } else {
      navigate(`/series/${series.id}`);
    }
  };

  const handleFavoriteClick = (e) => {
    e.stopPropagation(); // 阻止事件冒泡
    if (isFavorite) {
      removeFromFavorites(series.id);
    } else {
      addToFavorites(series);
    }
    setIsFavorite(!isFavorite);
  };

  return (
    <StyledCard onClick={handleClick}>
      <Box sx={{ position: 'relative' }}>
        <CardMedia
          component="img"
          height={compact ? "160" : "200"}
          image={series.thumbnail}
          alt={series.title}
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            objectFit: 'cover',
          }}
        />
        <FavoriteButton onClick={handleFavoriteClick}>
          {isFavorite ? <BookmarkIcon color="primary" /> : <BookmarkBorderIcon />}
        </FavoriteButton>
        {series.isWatched && (
          <StatusChip
            icon={<HistoryIcon />}
            label={`看到第${series.lastEpisode}集`}
            size="small"
          />
        )}
        <PlayIconWrapper className="play-icon">
          <PlayArrowIcon sx={{ color: 'white', fontSize: 40 }} />
        </PlayIconWrapper>
        <InfoOverlay>
          <TitleTypography variant={compact ? "subtitle1" : "h6"}>
            {series.title}
          </TitleTypography>
          <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
            <Chip
              label={series.status}
              size="small"
              sx={{
                bgcolor: 'primary.main',
                color: 'white',
                height: 20,
                '& .MuiChip-label': {
                  px: 1,
                  fontSize: '0.75rem',
                }
              }}
            />
            <Chip
              label={`${series.episodeCount}集`}
              size="small"
              sx={{
                bgcolor: 'rgba(255,255,255,0.2)',
                color: 'white',
                height: 20,
                '& .MuiChip-label': {
                  px: 1,
                  fontSize: '0.75rem',
                }
              }}
            />
            {!compact && series.tags?.slice(0, 2).map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                size="small"
                sx={{
                  bgcolor: 'rgba(255,255,255,0.2)',
                  color: 'white',
                  height: 20,
                  '& .MuiChip-label': {
                    px: 1,
                    fontSize: '0.75rem',
                  }
                }}
              />
            ))}
          </Box>
          {series.continueEpisode && (
            <ContinueButton
              variant="contained"
              size="small"
              startIcon={<PlayCircleOutlineIcon />}
              fullWidth
            >
              继续观看第{series.continueEpisode}集
            </ContinueButton>
          )}
        </InfoOverlay>
      </Box>
      {!compact && (
        <CardContent sx={{ pt: 1.5, pb: '12px !important' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="caption" color="text.secondary">
              {series.views} 次观看
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {series.rating ? `评分：${series.rating}` : ''}
            </Typography>
          </Box>
        </CardContent>
      )}
    </StyledCard>
  );
};

export default SeriesCard; 