import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Typography,
  Box,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Stack,
  CircularProgress,
  Alert,
  Tabs,
  Tab,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import SeriesCard from '../components/SeriesCard';
import { fetchVideoList, transformVideoData, fetchCategories, fetchSubCategories } from '../services/api';

const VideoList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [videos, setVideos] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [mainCategory, setMainCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // 从 URL 获取参数
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const categoryId = searchParams.get('t') || '';
    const searchQuery = searchParams.get('wd') || '';
    const pageNum = parseInt(searchParams.get('pg')) || 1;
    
    // 如果是子分类 ID，需要找到对应的主分类
    const subCat = subCategories.find(cat => cat.id.toString() === categoryId);
    if (subCat) {
      setMainCategory(subCat.parentId.toString());
      setSubCategory(categoryId);
    } else {
      setMainCategory(categoryId);
      setSubCategory('');
    }
    
    setSearchTerm(searchQuery);
    setPage(pageNum);
  }, [location.search, subCategories]);

  // 加载主分类数据
  useEffect(() => {
    const loadCategories = async () => {
      try {
        const data = await fetchCategories();
        setCategories(data.filter(cat => cat.id !== 0 && cat.id !== 5)); // 排除"推荐"和"伦理片"分类
      } catch (err) {
        console.error('Failed to load categories:', err);
      }
    };
    loadCategories();
  }, []);

  // 加载子分类数据
  useEffect(() => {
    const loadSubCategories = async () => {
      if (mainCategory) {
        try {
          const data = await fetchSubCategories(mainCategory);
          setSubCategories(data);
        } catch (err) {
          console.error('Failed to load sub-categories:', err);
        }
      } else {
        setSubCategories([]);
      }
    };
    loadSubCategories();
  }, [mainCategory]);

  // 加载视频列表
  const loadVideos = async () => {
    try {
      setLoading(true);
      setError(null);
      const params = {
        pg: page,
        t: subCategory || mainCategory || '',
        ...(searchTerm && { wd: searchTerm }),
      };
      const response = await fetchVideoList(params);
      
      if (response.code === 1) {
        const transformedVideos = response.list.map(item => transformVideoData(item));
        setVideos(transformedVideos);
        setTotalPages(Math.ceil(response.total / response.limit));
      } else {
        setError(response.msg || '加载失败');
      }
    } catch (error) {
      console.error('Failed to load videos:', error);
      setError('加载失败，请稍后重试');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadVideos();
  }, [page, mainCategory, subCategory, searchTerm]);

  const handleMainCategoryChange = (event) => {
    const value = event.target.value;
    setMainCategory(value);
    setSubCategory('');
    navigate(`/?${new URLSearchParams({
      ...(value && { t: value }),
      ...(searchTerm && { wd: searchTerm }),
    }).toString()}`);
  };

  const handleSubCategoryChange = (event) => {
    const value = event.target.value;
    setSubCategory(value);
    navigate(`/?${new URLSearchParams({
      ...(value && { t: value }),
      ...(searchTerm && { wd: searchTerm }),
    }).toString()}`);
  };

  const handlePageChange = (event, value) => {
    navigate(`/?${new URLSearchParams({
      ...(subCategory ? { t: subCategory } : mainCategory ? { t: mainCategory } : {}),
      ...(searchTerm && { wd: searchTerm }),
      ...(value > 1 && { pg: value }),
    }).toString()}`);
  };

  const handleSearch = () => {
    navigate(`/?${new URLSearchParams({
      ...(subCategory ? { t: subCategory } : mainCategory ? { t: mainCategory } : {}),
      ...(searchTerm && { wd: searchTerm }),
    }).toString()}`);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ mb: 4 }}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mb: 3 }}>
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel>主分类</InputLabel>
            <Select
              value={mainCategory}
              label="主分类"
              onChange={handleMainCategoryChange}
            >
              <MenuItem value="">全部</MenuItem>
              {categories.filter(cat => cat.id !== 0).map((cat) => (
                <MenuItem key={cat.id} value={cat.id}>
                  {cat.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
          {subCategories.length > 0 && (
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel>子分类</InputLabel>
              <Select
                value={subCategory}
                label="子分类"
                onChange={handleSubCategoryChange}
              >
                <MenuItem value="">全部</MenuItem>
                {subCategories.map((cat) => (
                  <MenuItem key={cat.id} value={cat.id}>
                    {cat.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <TextField
            label="搜索"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
            sx={{ flexGrow: 1 }}
          />
          <Button
            variant="contained"
            onClick={handleSearch}
            sx={{ height: '56px' }}
          >
            搜索
          </Button>
        </Stack>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>{error}</Alert>
      )}

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid container spacing={3}>
            {videos.map((video) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={video.id}>
                <SeriesCard series={video} />
              </Grid>
            ))}
          </Grid>

          {videos.length === 0 && !loading && !error && (
            <Typography variant="h6" align="center" sx={{ mt: 4 }}>
              暂无数据
            </Typography>
          )}

          {videos.length > 0 && (
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                size="large"
              />
            </Box>
          )}
        </>
      )}
    </Container>
  );
};

export default VideoList; 