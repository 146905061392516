import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline, Box, Fade } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { keyframes } from '@mui/system';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import VideoPlayer from './pages/VideoPlayer';
import Series from './pages/Series';
import SeriesDetail from './pages/SeriesDetail';
import SeriesPlayer from './pages/SeriesPlayer';
import VideoList from './pages/VideoList';
import History from './pages/History';
import Favorites from './pages/Favorites';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const heartBeat = keyframes`
  0% { transform: scale(1) translate(-50%, -50%); }
  25% { transform: scale(1.2) translate(-50%, -50%); }
  50% { transform: scale(1) translate(-50%, -50%); }
  75% { transform: scale(1.2) translate(-50%, -50%); }
  100% { transform: scale(1) translate(-50%, -50%); }
`;

const fadeOut = keyframes`
  0% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
`;

function App() {
  const [showWelcome, setShowWelcome] = useState(false);

  useEffect(() => {
    setShowWelcome(true);
    // 3秒后自动隐藏
    setTimeout(() => {
      setShowWelcome(false);
    }, 3000);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Navbar />
          <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default' }}>
            <Routes>
              <Route path="/" element={<VideoList />} />
              <Route path="/series/:id" element={<SeriesDetail />} />
              <Route path="/series/:id/play/:episode" element={<SeriesPlayer />} />
              <Route path="/history" element={<History />} />
              <Route path="/favorites" element={<Favorites />} />
            </Routes>
          </Box>
        </Box>
      </Router>

      {showWelcome && (
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            zIndex: 9999,
            animation: `${fadeOut} 5s ease-in-out forwards`,
            textAlign: 'center',
          }}
        >
          <FavoriteIcon 
            sx={{ 
              position: 'absolute',
              top: '50%',
              left: '50%',
              fontSize: 120,
              color: 'error.main',
              animation: `${heartBeat} 1s ease-in-out infinite`,
              transform: 'translate(-50%, -50%)',
              filter: 'drop-shadow(0 0 10px rgba(244, 67, 54, 0.5))',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: 'calc(50% + 80px)',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: 'error.main',
              fontSize: '24px',
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              textShadow: '0 0 10px rgba(244, 67, 54, 0.5)',
            }}
          >
            优优，要每天快乐哦~ ❤️
          </Box>
        </Box>
      )}
    </ThemeProvider>
  );
}

export default App;
