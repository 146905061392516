import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Typography,
  Box,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  CircularProgress,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SeriesCard from '../components/SeriesCard';
import { getWatchHistory, clearHistory } from '../services/api';

const History = () => {
  const [history, setHistory] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadHistory();
    // 每30秒刷新一次历史记录
    const interval = setInterval(loadHistory, 30000);
    return () => clearInterval(interval);
  }, []);

  const loadHistory = () => {
    try {
      setLoading(true);
      const watchHistory = getWatchHistory();
      setHistory(watchHistory);
    } catch (error) {
      console.error('Failed to load history:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleClearHistory = () => {
    clearHistory();
    setHistory([]);
    setOpenDialog(false);
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (history.length === 0) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Alert severity="info">暂无观看历史</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h5" component="h1">
          观看历史 ({history.length})
        </Typography>
        <IconButton 
          onClick={() => setOpenDialog(true)}
          color="error"
          title="清空历史记录"
        >
          <DeleteIcon />
        </IconButton>
      </Box>

      <Grid container spacing={2}>
        {history.map((item) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={`${item.id}_${item.updateTime}`}>
            <SeriesCard series={item} />
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>确认清空历史记录？</DialogTitle>
        <DialogContent>
          <Typography>
            此操作将清空所有观看历史记录，且不可恢复。
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>取消</Button>
          <Button onClick={handleClearHistory} color="error">
            确认清空
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default History; 