import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Typography,
  Box,
  Alert,
  CircularProgress,
} from '@mui/material';
import SeriesCard from '../components/SeriesCard';
import { getFavorites } from '../services/api';

const Favorites = () => {
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadFavorites();
    // 每30秒刷新一次收藏列表
    const interval = setInterval(loadFavorites, 30000);
    return () => clearInterval(interval);
  }, []);

  const loadFavorites = () => {
    try {
      setLoading(true);
      const favoritesList = getFavorites();
      setFavorites(favoritesList);
    } catch (error) {
      console.error('Failed to load favorites:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (favorites.length === 0) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Alert severity="info">暂无收藏内容</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h5" component="h1">
          我的收藏 ({favorites.length})
        </Typography>
      </Box>

      <Grid container spacing={2}>
        {favorites.map((item) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={`${item.id}_${item.addTime}`}>
            <SeriesCard series={item} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Favorites; 