import axios from 'axios';

// 根据环境设置基础 URL
const BASE_URL = process.env.NODE_ENV === 'production' 
  ? '/api.php/provide/vod'  // 生产环境使用相对路径
  : '/api.php/provide/vod'; // 开发环境使用代理

// 处理视频源URL
const processVideoUrl = (url) => {
  if (!url) return '';
  
  // 如果是相对路径，直接返回
  if (url.startsWith('/')) return url;
  
  try {
    const urlObj = new URL(url);
    // 生产环境下，将视频源URL转换为通过代理访问
    if (process.env.NODE_ENV === 'production') {
      return `/proxy-video${urlObj.pathname}${urlObj.search}`;
    }
    return url;
  } catch (e) {
    console.error('Invalid URL:', url);
    return url;
  }
};

// 获取分类列表
export const fetchCategories = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/?ac=list`);
    if (response.data.class) {
      // 获取主分类（type_pid === 0）
      const mainCategories = [
        { id: 0, name: '推荐' },
        ...response.data.class
          .filter(cat => cat.type_pid === 0)
          .map(cat => ({
            id: parseInt(cat.type_id),
            name: cat.type_name
          }))
      ];
      return mainCategories;
    }
    return [];
  } catch (error) {
    console.error('Error fetching categories:', error);
    throw error;
  }
};

// 获取子分类列表
export const fetchSubCategories = async (parentId) => {
  try {
    const response = await axios.get(`${BASE_URL}/?ac=list`);
    if (response.data.class) {
      // 获取指定父分类的子分类
      const subCategories = response.data.class
        .filter(cat => cat.type_pid === parseInt(parentId))
        .map(cat => ({
          id: parseInt(cat.type_id),
          name: cat.type_name,
          parentId: parseInt(cat.type_pid)
        }));
      return subCategories;
    }
    return [];
  } catch (error) {
    console.error('Error fetching sub-categories:', error);
    throw error;
  }
};

export const fetchVideoList = async (params = {}) => {
  try {
    const response = await axios.get(`${BASE_URL}/?ac=list`, { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching video list:', error);
    throw error;
  }
};

export const fetchVideoDetail = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/?ac=detail&ids=${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching video detail:', error);
    throw error;
  }
};

// 解析播放列表
const parsePlayList = (playUrl) => {
  if (!playUrl) return [];
  return playUrl.split('#').map(item => {
    const [title, url] = item.split('$');
    return {
      episode: parseInt(title.match(/\d+/)?.[0] || '1'),
      title,
      videoUrl: processVideoUrl(url)  // 处理视频URL
    };
  });
};

// 移除 HTML 标签的辅助函数
const stripHtmlTags = (html) => {
  if (!html) return '';
  return html.replace(/<[^>]*>/g, '')
            .replace(/&nbsp;/g, ' ')
            .replace(/&ldquo;/g, '"')
            .replace(/&rdquo;/g, '"')
            .replace(/&middot;/g, '·')
            .replace(/&hellip;/g, '...')
            .trim();
};

// 本地存储相关的工具函数
const WATCHED_KEY = 'watched_videos';
const CONTINUE_KEY = 'continue_videos';

export const getWatchedVideos = () => {
  try {
    return JSON.parse(localStorage.getItem(WATCHED_KEY) || '[]');
  } catch (e) {
    return [];
  }
};

export const addWatchedVideo = (videoId) => {
  try {
    const watched = getWatchedVideos();
    if (!watched.includes(videoId)) {
      watched.push(videoId);
      localStorage.setItem(WATCHED_KEY, JSON.stringify(watched));
    }
  } catch (e) {
    console.error('Error saving watched video:', e);
  }
};

export const getContinueVideos = () => {
  try {
    return JSON.parse(localStorage.getItem(CONTINUE_KEY) || '{}');
  } catch (e) {
    return {};
  }
};

export const saveContinueVideo = (videoId, episode) => {
  try {
    const continues = getContinueVideos();
    continues[videoId] = episode;
    localStorage.setItem(CONTINUE_KEY, JSON.stringify(continues));
  } catch (e) {
    console.error('Error saving continue video:', e);
  }
};

// 数据转换函数
export const transformVideoData = (item) => {
  // 获取观看状态
  const watched = getWatchedVideos();
  const continues = getContinueVideos();
  const isWatched = watched.includes(item.vod_id);
  const continueEpisode = continues[item.vod_id];

  // 解析分类标签
  const tags = [
    item.type_name,
    ...(item.vod_class ? item.vod_class.split(',') : []),
    ...(item.vod_tag ? item.vod_tag.split(',') : [])
  ].filter(Boolean);

  // 解析演员列表
  const cast = item.vod_actor ? item.vod_actor.split(',') : [];

  // 解析剧集数量
  const episodes = parsePlayList(item.vod_play_url);
  const episodeCount = item.vod_total || episodes.length || 1;

  // 处理视频简介
  const description = stripHtmlTags(item.vod_content || item.vod_blurb || '');
  const blurb = stripHtmlTags(item.vod_blurb || '');

  // 生成随机颜色背景图片
  const getRandomColor = () => {
    const gradients = [
      { colors: ['FF6B6B', 'FF8E8E', 'FFA5A5'], name: '暖红', text: 'FFFFFF' },
      { colors: ['4ECDC4', '45B7AF', '3CA29A'], name: '青绿', text: 'FFFFFF' },
      { colors: ['6C5CE7', '8067E8', '947AE9'], name: '靛紫', text: 'FFFFFF' },
      { colors: ['F9C449', 'FFCD5C', 'FFD66F'], name: '明黄', text: '333333' },
      { colors: ['A8E6CF', 'BEEBD7', 'D4F0DF'], name: '薄荷', text: '333333' },
      { colors: ['FF9A9E', 'FFABAF', 'FFBCC0'], name: '珊瑚', text: '333333' },
      { colors: ['88D3CE', '95D8D4', 'A2DDDA'], name: '蓝绿', text: '333333' },
      { colors: ['FEC3A6', 'FFCBB4', 'FFD3C2'], name: '蜜桃', text: '333333' },
      { colors: ['B5B9FF', 'C2C5FF', 'CFD1FF'], name: '薰衣草', text: '333333' },
      { colors: ['FFB7B2', 'FFC4C0', 'FFD1CE'], name: '粉橘', text: '333333' }
    ];
    return gradients[Math.floor(Math.random() * gradients.length)];
  };

  const gradient = getRandomColor();
  const name = item.vod_name;
  // 根据文字长度动态调整字体大小
  const fontSize = name.length <= 5 ? 0.4 : 
                  name.length <= 10 ? 0.3 :
                  name.length <= 15 ? 0.25 : 0.2;
  
  const placeholderImage = `https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=${gradient.colors[0]}&color=${gradient.text}&size=400&format=svg&bold=true&font-size=${fontSize}`;

  return {
    id: item.vod_id,
    title: item.vod_name,
    description,
    thumbnail: item.vod_pic || placeholderImage,
    episodeCount,
    episodes,
    status: item.vod_remarks || '更新中',
    tags,
    views: item.vod_hits?.toString() || '0',
    rating: parseFloat(item.vod_score) || 0,
    year: item.vod_year || new Date().getFullYear().toString(),
    region: item.vod_area || '中国大陆',
    director: item.vod_director || '',
    cast,
    updateTime: item.vod_time,
    duration: item.vod_duration,
    language: item.vod_lang,
    doubanScore: item.vod_douban_score,
    doubanId: item.vod_douban_id,
    totalScore: item.vod_score_all,
    scoreCount: item.vod_score_num,
    pubDate: item.vod_pubdate,
    blurb,
    playFrom: item.vod_play_from,
    isWatched,
    continueEpisode,
    isFavorite: isFavorite(item.vod_id),
  };
};

// 获取相关推荐
export const fetchRelatedVideos = async (currentVideo) => {
  try {
    // 根据当前视频的分类获取相关视频
    const params = {
      t: currentVideo.type_id,
      pg: 1,
      limit: 6,
    };
    const response = await axios.get(`${BASE_URL}/?ac=list`, { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching related videos:', error);
    throw error;
  }
};

// 播放进度记忆相关的工具函数
const PROGRESS_KEY = 'video_progress';

export const getVideoProgress = (videoId, episode) => {
  try {
    const progress = JSON.parse(localStorage.getItem(PROGRESS_KEY) || '{}');
    return progress[`${videoId}_${episode}`] || 0;
  } catch (e) {
    return 0;
  }
};

export const saveVideoProgress = (videoId, episode, currentTime) => {
  try {
    const progress = JSON.parse(localStorage.getItem(PROGRESS_KEY) || '{}');
    progress[`${videoId}_${episode}`] = currentTime;
    localStorage.setItem(PROGRESS_KEY, JSON.stringify(progress));
  } catch (e) {
    console.error('Error saving video progress:', e);
  }
};

// 观看历史相关的工具函数
const HISTORY_KEY = 'watch_history';

export const getWatchHistory = () => {
  try {
    const history = JSON.parse(localStorage.getItem(HISTORY_KEY) || '[]');
    return history;
  } catch (e) {
    return [];
  }
};

export const addToHistory = (videoData) => {
  try {
    const history = getWatchHistory();
    // 移除旧的相同记录
    const filteredHistory = history.filter(item => item.id !== videoData.id);
    // 添加到历史记录开头
    filteredHistory.unshift({
      id: videoData.id,
      title: videoData.title,
      thumbnail: videoData.thumbnail,
      episodeCount: videoData.episodeCount,
      status: videoData.status,
      tags: videoData.tags,
      views: videoData.views,
      rating: videoData.rating,
      updateTime: new Date().toISOString(),
      lastEpisode: videoData.continueEpisode || 1,
      isWatched: true,
      continueEpisode: videoData.continueEpisode
    });
    // 只保留最近100条记录
    const limitedHistory = filteredHistory.slice(0, 100);
    localStorage.setItem(HISTORY_KEY, JSON.stringify(limitedHistory));
    return limitedHistory;
  } catch (e) {
    console.error('Error saving watch history:', e);
    return [];
  }
};

export const clearHistory = () => {
  try {
    localStorage.removeItem(HISTORY_KEY);
  } catch (e) {
    console.error('Error clearing watch history:', e);
  }
};

// 收藏相关的工具函数
const FAVORITES_KEY = 'favorite_videos';

export const getFavorites = () => {
  try {
    return JSON.parse(localStorage.getItem(FAVORITES_KEY) || '[]');
  } catch (e) {
    return [];
  }
};

export const addToFavorites = (videoData) => {
  try {
    const favorites = getFavorites();
    if (!favorites.some(item => item.id === videoData.id)) {
      favorites.unshift({
        id: videoData.id,
        title: videoData.title,
        thumbnail: videoData.thumbnail,
        episodeCount: videoData.episodeCount,
        status: videoData.status,
        tags: videoData.tags,
        views: videoData.views,
        rating: videoData.rating,
        addTime: new Date().toISOString()
      });
      localStorage.setItem(FAVORITES_KEY, JSON.stringify(favorites));
    }
    return favorites;
  } catch (e) {
    console.error('Error saving favorite:', e);
    return [];
  }
};

export const removeFromFavorites = (videoId) => {
  try {
    const favorites = getFavorites();
    const updatedFavorites = favorites.filter(item => item.id !== videoId);
    localStorage.setItem(FAVORITES_KEY, JSON.stringify(updatedFavorites));
    return updatedFavorites;
  } catch (e) {
    console.error('Error removing favorite:', e);
    return [];
  }
};

export const isFavorite = (videoId) => {
  try {
    const favorites = getFavorites();
    return favorites.some(item => item.id === videoId);
  } catch (e) {
    return false;
  }
}; 