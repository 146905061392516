import React, { useState, useEffect } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  InputBase, 
  Box, 
  Button, 
  Menu, 
  MenuItem, 
  IconButton, 
  Avatar, 
  CircularProgress,
  useTheme,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Collapse,
  ListItemIcon,
  Divider,
  TextField,
  InputAdornment,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import MovieIcon from '@mui/icons-material/Movie';
import TvIcon from '@mui/icons-material/Tv';
import AnimationIcon from '@mui/icons-material/Animation';
import HistoryIcon from '@mui/icons-material/History';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchCategories, fetchSubCategories } from '../services/api';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const data = await fetchCategories();
        setCategories(data);
        
        // 如果是连续剧分类，加载子分类
        const tvSeriesCategory = data.find(cat => cat.name === '连续剧');
        if (tvSeriesCategory) {
          const subData = await fetchSubCategories(tvSeriesCategory.id);
          setSubCategories(subData);
        }
      } catch (error) {
        console.error('Failed to load categories:', error);
      } finally {
        setLoading(false);
      }
    };

    loadCategories();
  }, []);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleCategoryClick = (category) => {
    if (category.id === 0) {
      navigate('/');
    } else {
      navigate(`/?t=${category.id}`);
    }
    if (isMobile) {
      setDrawerOpen(false);
    }
  };

  const handleSearch = (event) => {
    if (event.key === 'Enter' && searchValue.trim()) {
      navigate(`/?wd=${encodeURIComponent(searchValue.trim())}`);
      setSearchValue('');
      if (isMobile) {
        setDrawerOpen(false);
      }
    }
  };

  const isCurrentPath = (category) => {
    const searchParams = new URLSearchParams(location.search);
    const currentCategory = searchParams.get('t');
    
    if (category.id === 0) {
      return location.pathname === '/' && !currentCategory;
    }
    
    if (category.id === 2) {
      return currentCategory === '2' || subCategories.some(sub => sub.id.toString() === currentCategory);
    }
    
    return currentCategory === category.id.toString();
  };

  // 获取要在顶部显示的分类
  const getTopCategories = () => {
    if (!categories.length) return [];
    return [
      categories[0], // 推荐
      categories.find(cat => cat.name === '连续剧'),
      categories.find(cat => cat.name === '电影'),
      categories.find(cat => cat.name === '动漫'),
    ].filter(Boolean);
  };

  const getCategoryIcon = (category) => {
    switch (category.name) {
      case '推荐':
        return <HomeIcon />;
      case '电影':
        return <MovieIcon />;
      case '连续剧':
        return <TvIcon />;
      case '动漫':
        return <AnimationIcon />;
      default:
        return null;
    }
  };

  const drawer = (
    <Box sx={{ width: 250 }} role="presentation">
      <List>
        {categories.map((category) => (
          <React.Fragment key={category.id}>
            <ListItemButton
              onClick={() => {
                if (category.id === 2) {
                  setExpandedCategory(expandedCategory === 2 ? null : 2);
                } else {
                  handleCategoryClick(category);
                }
              }}
              selected={isCurrentPath(category)}
            >
              <ListItemIcon>
                {getCategoryIcon(category)}
              </ListItemIcon>
              <ListItemText primary={category.name} />
              {category.id === 2 && (expandedCategory === 2 ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>
            {category.id === 2 && (
              <Collapse in={expandedCategory === 2} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {subCategories.map((subCategory) => (
                    <ListItemButton
                      key={subCategory.id}
                      sx={{ pl: 4 }}
                      onClick={() => handleCategoryClick(subCategory)}
                      selected={location.search.includes(`t=${subCategory.id}`)}
                    >
                      <ListItemText primary={subCategory.name} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter' && searchInput.trim()) {
      navigate(`/?wd=${encodeURIComponent(searchInput.trim())}`);
      setSearchInput('');
      if (isMobile) {
        setDrawerOpen(false);
      }
    }
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate('/')}
        >
          优宝私人视频站
        </Typography>
        {!isMobile && (
          <Box sx={{ flexGrow: 1, display: 'flex', ml: 4, gap: 2 }}>
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              getTopCategories().map((category) => (
                <Button
                  key={category.id}
                  color="inherit"
                  onClick={() => handleCategoryClick(category)}
                  sx={{
                    borderBottom: isCurrentPath(category) ? 2 : 0,
                    borderColor: 'white',
                  }}
                >
                  {category.name}
                </Button>
              ))
            )}
          </Box>
        )}
        <Box sx={{ flexGrow: isMobile ? 1 : 0 }} />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            color="inherit"
            onClick={() => navigate('/history')}
            title="观看历史"
          >
            <HistoryIcon />
          </IconButton>
          <IconButton
            color="inherit"
            onClick={() => navigate('/favorites')}
            title="我的收藏"
          >
            <BookmarkIcon />
          </IconButton>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, ml: 2 }}>
            <TextField
              size="small"
              placeholder="搜索..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onKeyPress={handleSearchKeyPress}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={handleSearch}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                sx: {
                  color: 'inherit',
                  bgcolor: 'rgba(255, 255, 255, 0.1)',
                  '&:hover': {
                    bgcolor: 'rgba(255, 255, 255, 0.2)',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(255, 255, 255, 0.3)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(255, 255, 255, 0.5)',
                  },
                }
              }}
            />
          </Box>
        </Box>
        <IconButton sx={{ ml: 2 }}>
          <Avatar sx={{ width: 32, height: 32 }}>U</Avatar>
        </IconButton>
      </Toolbar>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerToggle}
      >
        {drawer}
      </Drawer>
    </AppBar>
  );
};

export default Navbar; 