import React from 'react';
import { Card, CardContent, CardMedia, Typography, CardActionArea, Box, Avatar, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    '& .MuiCardMedia-root': {
      filter: 'brightness(0.8)',
    },
    '& .play-icon': {
      opacity: 1,
    },
  },
}));

const PlayIconWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  opacity: 0,
  transition: 'opacity 0.2s ease-in-out',
  zIndex: 1,
  width: 64,
  height: 64,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  borderRadius: '50%',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  }
}));

const DurationBadge = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 8,
  right: 8,
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  color: 'white',
  padding: '2px 4px',
  borderRadius: 4,
  fontSize: '0.75rem',
  display: 'flex',
  alignItems: 'center',
  gap: 4,
}));

const VideoCard = ({ video, compact = false }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/video/${video.id}`);
  };

  return (
    <StyledCard onClick={handleClick} sx={{ cursor: 'pointer' }}>
      <Box sx={{ position: 'relative' }}>
        <CardMedia
          component="img"
          height={compact ? "100" : "140"}
          image={video.thumbnail}
          alt={video.title}
          sx={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
        />
        <PlayIconWrapper className="play-icon">
          <PlayArrowIcon sx={{ color: 'white', fontSize: 40 }} />
        </PlayIconWrapper>
        <DurationBadge>
          <AccessTimeIcon sx={{ fontSize: 14 }} />
          {video.duration}
        </DurationBadge>
      </Box>
      <CardContent sx={{ pb: compact ? 1 : 2 }}>
        <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
          <Avatar
            sx={{ width: 24, height: 24 }}
          >
            {video.author?.[0]}
          </Avatar>
          <Box sx={{ flex: 1 }}>
            <Typography
              gutterBottom
              variant="subtitle1"
              component="div"
              noWrap
              sx={{
                fontSize: compact ? '0.875rem' : '1rem',
                mb: 0.5,
                fontWeight: 500,
              }}
            >
              {video.title}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ fontSize: compact ? '0.75rem' : '0.875rem' }}
            >
              {video.author}
            </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              component="div"
              sx={{ mt: 0.5 }}
            >
              {video.views} • {video.uploadDate}
            </Typography>
          </Box>
        </Box>
        {!compact && (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              mt: 1,
            }}
          >
            {video.description}
          </Typography>
        )}
      </CardContent>
    </StyledCard>
  );
};

export default VideoCard; 